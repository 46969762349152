// @flow
import { format } from 'react-string-format';

export function getEnvironment(domain: String, isVersionStaging: Boolean) {
  let currentEnvironment;
  switch (domain) {
    case 'www.magroup-online.com':
      currentEnvironment = format('{0}-{1}', 'LIVE', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    case 'https://wl-factory.pp.aws.emagin.eu/':
      currentEnvironment = format('{0}-{1}', 'PP', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    case 'wl-factory.uat.aws.emagin.eu':
      currentEnvironment = format('{0}-{1}', 'UAT1', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    case 'wlv3.uat2.aws.emagin.eu':
      currentEnvironment = format('{0}-{1}', 'UAT2', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    case 'wl-factory.int.aws.emagin.eu':
      currentEnvironment = format('{0}-{1}', 'INT', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    case 'localhost:3020':
      currentEnvironment = format('{0}-{1}', 'LOCAL', isVersionStaging ? 'STAGING' : 'LIVE');
      break;
    default:
      currentEnvironment = 'UNKNOWN';
  }
  return currentEnvironment;
}

export default getEnvironment;
